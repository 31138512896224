.globalLoaderClass {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  opacity: 0.9;
  min-height: 100px;
  min-width: 150px;
  padding: 1em;
  border-radius: 5px;
  transition: background-color 1s ease-in-out;
}

.globalLoaderClass.show {
  background-color: white;
}

.message {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.message.show {
  opacity: 1;
}