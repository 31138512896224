$palette-textColor-light: #CBCBCB;

.first-head-cell {
  border-right: none !important;
  border-bottom: medium solid $palette-textColor-light !important;
}

.second-head-cell {
  border-left: none !important;
  border-bottom: medium solid $palette-textColor-light !important;
}

.first-column {
  border-right: thin solid $palette-textColor-light !important;
  border-left: thin solid $palette-textColor-light !important;
}

.other-head-cell {
  border-top: 1px solid $palette-textColor-light !important;
  border-right: thin solid $palette-textColor-light !important;
  text-align: center !important;

  .Mui-TableHeadCell-Content {
    display: flex;
    justify-content: center;
  }
}

.other-column {
  border-right: thin solid $palette-textColor-light !important;
}
