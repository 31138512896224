.root-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100vw;
  overflow-x: auto;
}

.root-page .content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-top: 48px;
}

.root-page .outlet {
  flex-grow: 1;
  padding: 24px 24px 0px 74px; /* Adjust padding as needed */
  width: 100%;
}

