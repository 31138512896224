@use "../helpers/colors.scss" as *;

// TODO: add more headings as per the designs

.header {
  &-H1 {
    color: $color-black !important;
    font-size: 40px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 48px !important;
  }
  /* Heading H2 */
  &-H2 {
    color: $color-black !important;
    font-size: 24px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 28.8px !important;
  }

  /* Heading H3 */
  &-H3 {
    color: $color-black;
    font-size: 20px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
  }
  &-H3-bold {
    @extend .header-H3;
    font-weight: 700 !important;
  }

  /* Heading H4 */
  &-H4 {
    color: $color-black !important;
    font-size: 18px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
  }
  &-H4-bold {
    @extend .header-H4;
    font-weight: 700 !important;
  }
}
.sub-heading {
  color: grey !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}
