@use "../../../assets/styles/helpers/colors.scss" as *;

.MuiChip-root {
  height: 30px !important;
  border-radius: 12px !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

