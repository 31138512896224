.header {
  width: 100%;
  height: 54px;
  background-color: #fff;
  backdrop-filter: none;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  z-index: 20;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.header .logo {
  height: 42px;
  width: 42px;
}

.header .settings {
  height: 48px;
  display: flex;
  align-items: center;
}
