.scenarioSummary {
  .MuiAccordionSummary-root {
    border-bottom: 0 !important;
  }

  .css-g6mgo7-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
    border-bottom: 1px solid #cbcbcb !important;
  }

  .no-scenarios-box {
    display: flex;
    justify-content: center;
    border: 1px dashed #cbcbcb;
    border-radius: 4px;
    min-height: 100% !important;
    padding-top: 20vh;
    padding-bottom: 20vh;
  }

  p {
    color: #6A6A6A;
    display: flex;
    align-items: center;
  }
}
