.text-edit {
  .MuiOutlinedInput-root {
    height: 55px;
    font-size: 24px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    padding: 0 !important;
    margin: 0 !important;

    .MuiInputBase-input {
      border-radius: 8px;
    }
  }
}
