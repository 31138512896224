.dataExtractionAccordion {
  padding: 0 0.5em;
  border: 1px solid #d3d3d3;
  margin: 1em 0;
  border-radius: 5px !important;

  &.checkedStyle {
    border: 1px solid #d04a02 !important;
  }
}

.dataExtractionAccordionSummary {
  border-bottom: none !important;
  height: 3em;
}

.dataExtractionAccordionSummary.expanded {
  border-bottom: 1px solid #d3d3d3 !important;
}

.dataExtractionAccordionDetails {
  margin: 1em 0.7em;
}