.modal {
  position: fixed;
  min-width: 1000px;
  height: 80%;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;

  transform: translate(-50%, -50%);
  background-color: white;

  padding: 40px;
  border-radius: 5px;
  overflow: scroll;
  overflow-y: scroll;

  display: flex;
  flex-direction: column;
  align-items: center;
}

::-webkit-scrollbar-track {
  margin-top: 5px;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.markdown-content {
  text-align: justify;
  text-justify: inter-word;
}
.markdown-content-li {
  margin-left: 20px !important;
  margin-top: 10px !important;
}

.markdown-content-strong {
  font-weight: 700 !important;
}

.policy-page {
  ol {
    list-style: decimal !important;
  }

  ul {
    list-style: disc !important;
  }

  td {
    padding: 10px !important;
  }

  tr {
    border-bottom: 1px solid black !important;
  }

  table {
    margin-top: 20px !important;
    margin-right: 20px !important;
    margin-left: 20px !important;
  }
}