@use "../../../assets/styles/helpers/colors.scss" as *;

.sidebar {
  width: 54px;
  height: calc(100% - 54px);
  top: 54px;
  background-color: $color-white;
  border-bottom: 0.5px solid $color-textColor-light !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  z-index: -10;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.sidebar ul {
  list-style-type: none;
}

.sidebar ul .sidebar-list-item {
  height: 46px;
  width: 46px;
  margin: 4px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
}

.sidebar ul .sidebar-list-item:hover {
  background-color: #eaeaea;
  cursor: pointer;
}

.sidebar ul .sidebar-list-item a {
  color: $color-black;
  text-decoration: none !important;
}

.sidebar ul .active {
  background-color: #eaeaea;
  fill: $color-primary;
}
