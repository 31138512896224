$font-size-body-xs: 12px;
$font-size-body-s: 14px;
$font-size-body: 16px;
$font-size-subheading: 20px;
$font-size-heading: 24px;
$font-size-large-heading: 36px;
$font-size-data: 48px;
$font-size-title: 56px;
$font-size-subtitle: 40px;

.two-line-text-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
