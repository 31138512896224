.markdown-content {
  text-align: justify;
  text-justify: inter-word;
}
.markdown-content-li {
  margin-left: 20px !important;
  margin-top: 10px !important;
}

.markdown-content-strong {
  font-weight: 700 !important;
}

.policy-page {
  ol {
    list-style: decimal !important;
  }

  ul {
    list-style: disc !important;
  }

  td {
    padding: 10px !important;
  }

  tr {
    border-bottom: 1px solid black !important;
  }

  table {
    margin-top: 20px !important;
    margin-right: 20px !important;
    margin-left: 20px !important;
  }
}
