@use "../helpers/colors.scss" as *;

.MuiAccordion-root {
  box-shadow: none !important;
  position: unset !important;

  .MuiAccordionSummary-root {
    padding: 0 !important;
    border-bottom: 0.5px solid #cbcbcb;

    &.no-border {
      border-bottom: none;
    }
  }

  .MuiAccordionDetails-root {
    padding: 0 !important;
  }

  .macro-accordion .MuiTableHead-root {
    display: contents !important;
  }

  .MuiTableHead-root {
    display: none !important;
  }

  .MuiAccordionSummary-expandIconWrapper {
    color: $color-primary;
  }
}

.MuiAccordion-root.Mui-disabled {
  background-color: transparent !important;
}

.dropdown-left {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;

  .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
    transform: rotate(90deg);
  }

  .MuiAccordionSummary-content,
  .MuiAccordionSummary-content.Mui-expanded {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
