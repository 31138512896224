.button {
  border: none !important;

  &.clear {
    margin-left: auto;
    margin-right: 0.5em;
    height: 1.5em;
  }

  &.footer {
    width: 50%;
  }
}
