@use "../../../assets/styles/helpers/colors.scss" as *;

.table-wrapper {
  .MuiPaper-root {
    box-shadow: none;
  }

  .MuiIconButton-root {
    padding: 0 !important;
    margin: 0 !important;
  }

  .MuiIconButton-root {
    color: $color-primary;
  }

  .Mui-TableBodyCell-DetailPanel {
    .MuiCollapse-vertical {
      width: inherit !important;
    }
  }
}
