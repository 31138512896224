:root {
    --nivo-colour-1: #9e0142;
    --nivo-colour-2: #d53e4f;
    --nivo-colour-3: #f46d43;
    --nivo-colour-4: #fdae61;
    --nivo-colour-5: #fee08b;
    --nivo-colour-6: #ffffbf;
    --nivo-colour-7: #e6f598;
    --nivo-colour-8: #abdda4;
    --nivo-colour-9: #66c2a5;
    --nivo-colour-10: #3288bd;
    --nivo-colour-11: #5e4fa2;
  }