@use "../../../assets/styles/helpers/colors" as *;

.micro-table-wrapper {
  .MuiPaper-root {
    box-shadow: none;

    .MuiTable-root {
      .MuiTableRow-root {
        .MuiTableCell-root:first-child {
          border-left: none !important;
        }
        .MuiTableCell-root:last-child {
          border-right: none !important;
        }
        .MuiTableCell-root {
          border-right: 1px solid $color-textColor-light;

          & .MuiBox-root {
            gap: 0.2rem;
          }
        }
      }
    }
  }
  .MuiIconButton-root {
    padding: 0 !important;
    margin: 0 !important;
  }

  .MuiIconButton-root {
    color: $color-primary;
  }

  .Mui-TableBodyCell-DetailPanel {
    .MuiCollapse-vertical {
      width: inherit !important;
    }
  }
}
