.percentage-bar-container {
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 4px;
  overflow: hidden;
  height: 40px;
}

.percentage-bar {
  height: 100%;
  background-color: #d04a02;
  transition: width 0.3s ease-in-out;
}

.orange-text {
  color: #d04a02;
}

.bold-numbers {
  font-weight: 500;
  font-size: 24px;
  line-height: 28.8px;
  color: #333333;
}
