* {
  font-family: "Helvetica Neue", Arial, sans-serif !important;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

html,
body {
  height: 100%;
  font-family: "Helvetica Neue", Arial, sans-serif !important;
  display: flex;
  flex: 1;
  overflow-x: hidden;
}

#root {
  width: 100%;
  height: 100%;
}

.App {
  width: 100%;
  min-height: 100%;
}
