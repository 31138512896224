.banner-general {
  transition: all 0.5s ease;
  position: absolute;
  top: 3.4em;
  padding: 1em;
  margin-left: 3.4em;
  border-bottom-left-radius: 0.25em;
  border-bottom-right-radius: 0.25em;
  min-height: 72px;
  width: calc(100% - 3.4em);
  z-index: 10;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

  &.error {
    background-color: #e8aaa3;
    color: #333333;
  }

  &.information {
    background-color: #c3d3e2;
    color: #40576d ;
  }

  &.success {
    background-color: #d3ebd5;
    color: #20522a;
  }

  &.warning {
    background-color: #ffe5a5;
    color: #786124;
  }
}

.banner-preview {
  padding: 1em;
  border-radius: 0.25em;
  margin-top: 1em;
  min-height: 72px;
  width: 100%;
  z-index: 10;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

  &.error {
    background-color: #e8aaa3;
    color: #333333;
  }

  &.information {
    background-color: #c3d3e2;
    color: #40576d ;
  }

  &.success {
    background-color: #d3ebd5;
    color: #20522a;
  }

  &.warning {
    background-color: #ffe5a5;
    color: #786124;
  }
}

.banner-button-general {
  &.error {
    border-color: #612622 !important;
    color: #6e2a2a !important;
  }

  &.information {
    border-color: #40576d !important;
    color: #25698d !important;
  }

  &.success {
    border-color: #20522a !important;
    color: #20522a !important;
  }

  &.warning {
    border-color: #786124 !important;
    color: #714300 !important;
  }
}

.bannerVisible {
  opacity: 1 !important;
  transform: translateY(0) !important;
}

.bannerInvisible {
  opacity: 0 !important;
  transform: translateY(-100%) !important;
}
