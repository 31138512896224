@import "../../../assets/styles/base/index";

.landing-image {
  background-image: url("../../../assets/images/LandingPage4.svg");
  background-size: 200%;
  background-repeat: no-repeat;
  background-position: left;

  @media (max-width: 1000px) {
    background-size: 220%;
    transition: background-size 0.3s ease-in-out;
  }
  @media (max-width: 1200px) {
    background-size: 250%;
    transition: background-size 0.3s ease-in-out;
  }
  @media (max-width: 1400px) {
    background-size: 300%;
    transition: background-size 0.3s ease-in-out;
  }
  @media (max-height: 600px) {
    display: none;
  }
}

.landing-logo {
  position: absolute;
  top: 1vh;
  left: 6vw;
  min-width: 6rem;
  max-width: 12rem;
  object-fit: contain;

  @media (max-height: 600px) {
    display: none;
  }
}