.scenario-tile {
    height: 100%;
    border: 1px solid #cbcbcb;
    border-radius: 4px;
    padding: 1rem;
}

.no-scenarios-box {
    display: flex;
    justify-content: center;
    border: 1px dashed #cbcbcb;
    border-radius: 4px;
    min-height: 100% !important;
    padding-top: 20vh;
    padding-bottom: 20vh;
}

.scenario-title {
    color: #d04a02 !important;

    &:hover {
        cursor: pointer;
    }
}